<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000px"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Store</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm" class="pa-3 pb-0 row">
            <div
              class="col-12 col-sm-6 py-0 my-0"
              v-if="serverData.hasOwnProperty('organizations')"
            >
              <v-autocomplete
                v-model="formData.organization_id"
                label="Organization"
                item-text="text"
                item-value="index"
                :items="serverData.organizations"
                clearable
                outlined
                dense
                :error-messages="organization_idErrors"
                @input="$v.formData.organization_id.$touch()"
                @blur="$v.formData.organization_id.$touch()"
              />
            </div>
            <div
              class="col-12 py-0 my-0"
              :class="{
                'col-md-6': serverData.hasOwnProperty('organizations'),
              }"
            >
              <v-autocomplete
                v-model="formData.type_id"
                label="Type"
                item-text="text"
                item-value="index"
                :items="serverData.types"
                clearable
                outlined
                dense
                :error-messages="type_idErrors"
                @input="$v.formData.type_id.$touch()"
                @blur="$v.formData.type_id.$touch()"
              />
            </div>
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-autocomplete
                v-model="formData.customer_id"
                label="Customer"
                item-text="text"
                item-value="index"
                :items="serverData.clients"
                clearable
                outlined
                dense
                :error-messages="customer_idErrors"
                @input="$v.formData.customer_id.$touch()"
                @blur="$v.formData.customer_id.$touch()"
              />
            </div>
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-autocomplete
                @change="onCountryChange"
                v-model="formData.country_id"
                label="Country"
                item-text="text"
                item-value="index"
                :items="serverData.countries"
                clearable
                outlined
                dense
              />
            </div>
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-autocomplete
                @change="onStateChange"
                :disabled="isStatesLoaded"
                v-model="formData.state_id"
                label="State"
                item-text="title"
                item-value="id"
                :items="serverData.states"
                clearable
                outlined
                dense
              />
            </div>
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-autocomplete
                @change="onCityChange"
                :disabled="isCitiesLoaded"
                v-model="formData.city_id"
                label="City"
                item-text="title"
                item-value="id"
                :items="serverData.cities"
                clearable
                outlined
                dense
              />
            </div>
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-autocomplete
                :disabled="isAreasLoaded"
                v-model="formData.area_id"
                label="Area"
                item-text="title"
                item-value="id"
                :items="serverData.areas"
                clearable
                outlined
                dense
              />
            </div>
            <!--            <div class="col-12 col-sm-6 py-0 my-0">-->
            <!--              <v-autocomplete-->
            <!--                @change="onCountryChange"-->
            <!--                v-model="formData.country_id"-->
            <!--                label="Country"-->
            <!--                item-text="text"-->
            <!--                item-value="index"-->
            <!--                :items="serverData.countries"-->
            <!--                clearable-->
            <!--                outlined-->
            <!--                dense-->
            <!--              />-->
            <!--            </div>-->
            <!--            <div class="col-12 col-sm-6 py-0 my-0">-->
            <!--              <v-autocomplete-->
            <!--                @change="onStateChange"-->
            <!--                :disabled="isStatesLoaded"-->
            <!--                v-model="formData.state_id"-->
            <!--                label="State"-->
            <!--                item-text="title"-->
            <!--                item-value="id"-->
            <!--                :items="serverData.states"-->
            <!--                clearable-->
            <!--                outlined-->
            <!--                dense-->
            <!--              />-->
            <!--            </div>-->
            <!--            <div class="col-12 col-sm-6 py-0 my-0">-->
            <!--              <v-autocomplete-->
            <!--                @change="onCityChange"-->
            <!--                :disabled="isCitiesLoaded"-->
            <!--                v-model="formData.city_id"-->
            <!--                label="City"-->
            <!--                item-text="title"-->
            <!--                item-value="id"-->
            <!--                :items="serverData.cities"-->
            <!--                clearable-->
            <!--                outlined-->
            <!--                dense-->
            <!--              />-->
            <!--            </div>-->
            <!--            <div class="col-12 col-sm-6 py-0 my-0">-->
            <!--              <v-autocomplete-->
            <!--                :disabled="isAreasLoaded"-->
            <!--                v-model="formData.area_id"-->
            <!--                label="Area"-->
            <!--                item-text="title"-->
            <!--                item-value="id"-->
            <!--                :items="serverData.areas"-->
            <!--                clearable-->
            <!--                outlined-->
            <!--                dense-->
            <!--              />-->
            <!--            </div>-->
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-autocomplete
                v-model="formData.location_id"
                label="Location"
                item-text="text"
                item-value="index"
                :items="serverData.locations"
                clearable
                outlined
                dense
              />
            </div>
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-text-field
                v-model="formData.supplier_code"
                label="Code"
                clearable
                outlined
                dense
                :error-messages="supplier_codeErrors"
                @input="$v.formData.supplier_code.$touch()"
                @blur="$v.formData.supplier_code.$touch()"
              />
            </div>
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-text-field
                v-model="formData.store_name"
                label="Name"
                clearable
                outlined
                dense
              />
            </div>
            <!--            <div class="col-12 py-0 my-0">-->
            <!--              <v-text-field-->
            <!--                v-model="formData.email"-->
            <!--                label="Email"-->
            <!--                clearable-->
            <!--                outlined-->
            <!--                dense-->
            <!--                :error-messages="emailErrors"-->
            <!--                @input="$v.formData.email.$touch()"-->
            <!--                @blur="$v.formData.email.$touch()"-->
            <!--              />-->
            <!--            </div>-->
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-autocomplete
                v-model="formData.brands"
                label="Brands"
                item-text="text"
                item-value="index"
                :items="serverData.brands"
                clearable
                outlined
                dense
                multiple
              />
            </div>
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-text-field
                v-model="formData.contact_name"
                label="Contact Name"
                clearable
                outlined
                dense
              />
            </div>
            <!--            <div class="col-12 col-sm-6 py-0 my-0">-->
            <!--              <v-text-field-->
            <!--                v-model="formData.phone"-->
            <!--                label="Phone"-->
            <!--                clearable-->
            <!--                outlined-->
            <!--                dense-->
            <!--              />-->
            <!--            </div>-->
            <!--            <div class="col-12 col-sm-6 py-0 my-0">-->
            <!--              <v-text-field-->
            <!--                v-model="formData.mobile"-->
            <!--                label="Mobile"-->
            <!--                clearable-->
            <!--                outlined-->
            <!--                dense-->
            <!--              />-->
            <!--            </div>-->
            <!--            <div class="col-12 col-sm-6 py-0 my-0">-->
            <!--              <v-text-field-->
            <!--                v-model="formData.address"-->
            <!--                label="Address"-->
            <!--                clearable-->
            <!--                outlined-->
            <!--                dense-->
            <!--              />-->
            <!--            </div>-->
            <div class="h-75px col-12 col-sm-6">
              <VueTelInputVuetify
                class="ma-0 pa-0 mx-1"
                outlined
                v-model="formData.phone"
                :inputOptions="{ showDialCode: false, tabindex: 0 }"
                mode="international"
                dense
                disabledFetchingCountry
                defaultCountry="AE"
                :error-messages="phoneErrors"
                @input="$v.formData.phone.$touch()"
                @blur="$v.formData.phone.$touch()"
              />
            </div>
            <div class="h-75px col-12 col-sm-6">
              <v-text-field
                v-model="formData.email"
                label="Email"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                @change="onCollectionCountryChange"
                v-model="formData.country_id"
                label="Country"
                item-text="text"
                item-value="index"
                :items="collectionAddressServerData.countries"
                clearable
                outlined
                dense
                :error-messages="collectionCountryErrors"
                @input="$v.formData.country_id.$touch()"
                @blur="$v.formData.country_id.$touch()"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-combobox
                v-model="formData.state_id"
                :search-input.sync="collection_state_text"
                :items="collectionAddressServerData.states"
                item-text="title"
                item-value="id"
                label="State"
                persistent-hint
                outlined
                dense
                :error-messages="collectionStateErrors"
                @input="$v.formData.state_id.$touch()"
                @blur="$v.formData.state_id.$touch()"
                @change="onCollectionStateChange"
                @keyup="updateValue('collection_state')"
                :disabled="isCollectionStatesLoaded"
                :rules="[validationValueCheck('collection_state')]"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{
                          collection_state_text
                        }}</strong
                        >".
                        <button
                          class="btn btn-info pl-2"
                          @click="
                            createNewItem(
                              'state',
                              formData.country_id,
                              onCollectionCountryChange,
                              collection_state_text
                            )
                          "
                        >
                          Create new
                        </button>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </div>
            <!-- row 2 end -->

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-combobox
                v-model="formData.city_id"
                :search-input.sync="collection_city_text"
                :items="collectionAddressServerData.cities"
                item-text="title"
                item-value="id"
                label="City"
                persistent-hint
                outlined
                dense
                :error-messages="collectionCityErrors"
                @input="$v.formData.city_id.$touch()"
                @blur="$v.formData.city_id.$touch()"
                @change="onCollectionCityChange"
                @keyup="updateValue('collection_city')"
                :disabled="isCollectionCitiesLoaded"
                :rules="[validationValueCheck('collection_city')]"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{
                          collection_city_text
                        }}</strong
                        >".
                        <button
                          class="btn btn-info pl-2"
                          @click="
                            createNewItem(
                              'city',
                              formData.state_id,
                              onCollectionStateChange,
                              collection_city_text
                            )
                          "
                        >
                          Create new
                        </button>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.area_id"
                :search-input.sync="collection_area_text"
                :items="collectionAddressServerData.areas"
                item-text="title"
                item-value="id"
                label="Area"
                persistent-hint
                outlined
                dense
                @keyup="updateValue('collection_area')"
                :disabled="isCollectionAreasLoaded"
                :rules="[validationValueCheck('collection_area')]"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{
                          collection_area_text
                        }}</strong
                        >".
                        <button
                          class="btn btn-info pl-2"
                          @click="
                            createNewItem(
                              'area',
                              formData.city_id,
                              onCollectionCityChange,
                              collection_area_text
                            )
                          "
                        >
                          Create new
                        </button>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </div>

            <div class="h-75px col-12 col-sm-6">
              <v-text-field
                v-model="$v.formData.address.$model"
                label="Address 1"
                clearable
                outlined
                dense
                :error-messages="addressErrors"
                @input="$v.formData.address.$touch()"
                @blur="$v.formData.address.$touch()"
              />
            </div>
            <div class="h-75px col-12 col-sm-6">
              <v-text-field
                v-model="formData.address2"
                label="Address 2"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6">
              <v-autocomplete
                v-model="formData.is_default"
                label="Default address"
                item-text="text"
                item-value="index"
                :items="booleanTypes"
                clearable
                outlined
                dense
                :error-messages="is_defaultErrors"
                @input="$v.formData.is_default.$touch()"
                @blur="$v.formData.is_default.$touch()"
              />
            </div>
            <div class="h-75px col-12 col-sm-6">
              <v-text-field
                v-model="formData.postal_code"
                label="Postal code"
                clearable
                outlined
                dense
              />
            </div>
            <div class="col-12 py-0 my-0">
              <v-text-field
                v-model="formData.remarks"
                label="Remarks"
                clearable
                outlined
                dense
              />
            </div>
            <div class="col-12 py-0 my-0">
              <v-text-field
                v-model="formData.instructions"
                label="Instructions"
                clearable
                outlined
                dense
              />
            </div>
          </form>

          <CreateLocation
            :location-id="getLocationId"
            :location-type="getLocationType"
            :location-function="getLocationFunc"
            :locationSetValue="setTextValue"
            :initValue="getLocationInitValue"
            ref="location"
          />
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import { validationMixin } from "vuelidate";
import { email, required, requiredIf } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import CreateLocation from "@/own/components/warehouseManagement/store/CreateLocation.vue";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";

export default {
  name: "EditItem",
  components: { VueTelInputVuetify, CreateLocation },
  mixins: [validationMixin],
  validations: {
    formData: {
      is_default: { required },
      supplier_code: { required },
      customer_id: { required },
      type_id: { required },
      email: { email },
      phone: { required },
      country_id: { required },
      state_id: { required },
      city_id: { required },
      address: { required },
      organization_id: {
        required: requiredIf(function() {
          return this.serverData.hasOwnProperty("organizations");
        }),
      },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    collectionAddressServerData: {},

    collection_state_text: null,
    collection_city_text: null,
    collection_area_text: null,

    location_type: null,
    location_id: "",
    location_function: null,

    location_initValue: "", // before was null

    formData: {
      id: null,
      is_default: null,
      supplier_id: null,
      customer_id: null,
      supplier_code: null,
      store_name: null,
      contact_name: null,
      phone: null,
      address: null,
      area_id: null,
      city_id: null,
      state_id: null,
      country_id: null,
      remarks: null,
      instructions: null,
      location_id: null,
      brands: null,
      organization_id: null,
      type_id: null,
      email: null,
      address2: null,
      postal_code: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("email" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/warehouse_management/client_stores/edit", data)
        .then(({ data }) => {
          this.collectionAddressServerData = {
            countries: data.countries,
            states: data.states,
            cities: data.cities,
            areas: [...data.areas],
          };

          setTimeout(() => {
            this.formData = { ...data.supplier };
            // this.collection_state_text = data.supplier.state_id.toString();
            // this.collection_city_text = data.supplier.city_id.toString();

            this.formData.state_id = data.states.find(
              (item) => item.id === data.supplier.state_id
            );
            this.formData.city_id = data.cities.find(
              (item) => item.id === data.supplier.city_id
            );

            if (data.supplier.area_id) {
              // this.collection_area_text = data.supplier.area_id.toString();

              this.formData.area_id = data.areas.find(
                (item) => item.id === data.supplier.area_id
              )?.id;
            }

            this.serverData = {
              types: data.types,
              brands: data.brands,
              clients: data.clients,
              locations: data.locations,
              // organizations: data.organizations,
            };

            if (data?.organizations) {
              this.serverData.organizations = data.organizations;
            }

            this.pageLoader(false);
          }, 300);
          // this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
          this.dialog = false;
        });
    },
    updateValue(type) {
      let timer;
      const waitTime = 1250;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, waitTime);
    },
    onBlurValueCheck(type) {
      switch (type) {
        case "collection_state": {
          const item = this.collectionAddressServerData.states.find(
            (state) => state.title === this.collection_state_text
          );

          if (!item) {
            this.onCollectionStateChange(item);
          }

          break;
        }

        case "collection_city": {
          const item = this.collectionAddressServerData.cities.find(
            (city) => city.title === this.collection_city_text
          );

          if (!item) {
            this.onCollectionCityChange(item);
          }
          break;
        }

        case "collection_area": {
          const item = this.collectionAddressServerData.areas.find(
            (area) => area.title === this.collection_area_text
          );

          if (!item) {
            this.formData.area_id = item.id;
          }
          break;
        }
      }
    },
    validationValueCheck(type) {
      let validation = true;
      switch (type) {
        case "collection_state":
          try {
            if (
              !this.collectionAddressServerData.states.find(
                (state) => state.title === this.collection_state_text
              )
            ) {
              validation = "Please, select valid state!";
            }
          } catch {
            break;
          }

          break;

        case "collection_city":
          try {
            if (
              !this.collectionAddressServerData.cities.find(
                (city) => city.title === this.collection_city_text
              )
            ) {
              validation = "Please, select valid city!";
            }
          } catch {
            break;
          }

          break;

        case "collection_area":
          try {
            if (!this.collection_area_text) {
              validation = true;
              break;
            } else if (
              !this.collectionAddressServerData.areas.find(
                (area) => area.title === this.collection_area_text
              )
            ) {
              validation = "Please, select valid area!";
            }
          } catch {
            break;
          }

          break;
      }
      return validation;
    },
    async setTextValue(type, value = "") {
      switch (type) {
        case "collection_state":
          this.collection_state_text = value;

          if (value !== "") {
            this.formData.state_id = this.collectionAddressServerData.states.find(
              (state) => state.title === this.collection_state_text
            );
            await this.onCollectionStateChange(this.collection_state_text);
          }

          break;

        case "collection_city":
          this.collection_city_text = value;

          if (value !== "") {
            this.formData.city_id = this.collectionAddressServerData.cities.find(
              (city) => city.title === this.collection_city_text
            );
            await this.onCollectionCityChange(this.collection_city_text);
          }
          break;

        case "collection_area":
          this.collection_area_text = value;
          break;
      }
    },
    async onCollectionCountryChange(val, is_id = false) {
      if (val) {
        this.pageLoader(true);
        let data = is_id ? { country: val } : { country: val };
        await ApiService.post("/address/states/search", data)
          .then((response) => {
            this.collectionAddressServerData.states = response.data.states;

            this.collectionAddressServerData = {
              ...this.collectionAddressServerData,
            };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },

    async onCollectionStateChange(val, is_id = false) {
      // console.log("onCollectionStateChange", val);
      if (val) {
        let data = { state: null };
        if (is_id) {
          data.state = val;
        } else {
          let id = null;
          try {
            id = this.collectionAddressServerData.states.find(
              (state) => state.title === val.title
            )?.id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }
          data.state = id;
        }
        this.pageLoader(true);

        await ApiService.post("/address/cities/search", data)
          .then((response) => {
            // this.serverCities = response.data.cities;
            this.collectionAddressServerData.cities = response.data.cities;

            this.collectionAddressServerData = {
              ...this.collectionAddressServerData,
            };

            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },

    async onCollectionCityChange(val, is_id = false) {
      if (val) {
        let data = { city: null };
        if (is_id) {
          data.city = val;
        } else {
          let id = null;
          try {
            id = this.collectionAddressServerData.cities.find(
              (city) => city.title === val.title
            )?.id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }

          data.city = id;
        }

        this.pageLoader(true);
        await ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.collectionAddressServerData.areas = response.data.areas;

            this.collectionAddressServerData = {
              ...this.collectionAddressServerData,
            };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
    createNewItem(type, id, func, value = "") {
      // console.log(type, id, func);
      this.location_type = type;
      this.location_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.pageLoader(true);
      ApiService.post("/warehouse_management/client_stores/update", {
        ...this.formData,
        state_id: this.formData.state_id.id,
        city_id: this.formData.city_id.id,
        // area_id: this.formData.area_id?.id,
        area_id: this.formData.area_id,
      })
        .then(() => {
          swalEdited();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.supplier };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.supplier).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
              // console.log(serverData[0], copy[serverData[0]]);
            }
          });
        });
      } else {
        this.formData = {
          id: null,
          supplier_id: null,
          customer_id: null,
          supplier_code: null,
          store_name: null,
          contact_name: null,
          phone: null,
          address: null,
          area_id: null,
          city_id: null,
          state_id: null,
          country_id: null,
          remarks: null,
          instructions: null,
          location_id: null,
          brands: null,
        };
      }
      this.formData = {
        id: null,
        is_default: null,
        supplier_id: null,
        customer_id: null,
        supplier_code: null,
        store_name: null,
        contact_name: null,
        remarks: null,
        instructions: null,
        location_id: null,
        brands: null,
        organization_id: null,
        type_id: null,
        phone: null, //required
        email: null,
        address: null, //required
        address2: null,
        postal_code: null,
        country_id: null,
        state_id: null,
        city_id: null,
        area_id: null,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function() {
      return !!this.serverData;
    },
    booleanTypes: function() {
      return [
        { index: 0, text: "No" },
        { index: 1, text: "Yes" },
      ];
    },
    is_defaultErrors() {
      return this.handleFormValidation("is_default");
    },
    supplier_codeErrors() {
      return this.handleFormValidation("supplier_code");
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id");
    },
    states: function() {
      return this.serverData.states;
    },
    cities: function() {
      return this.serverData.cities;
    },
    areas: function() {
      return this.serverData.areas;
    },
    isStatesLoaded: function() {
      return !this.serverData.states;
    },
    isCitiesLoaded: function() {
      return !this.serverData.cities;
    },
    isAreasLoaded: function() {
      return !this.serverData.areas;
    },
    organization_idErrors() {
      return this.handleFormValidation("organization_id");
    },
    type_idErrors() {
      return this.handleFormValidation("type_id");
    },
    // emailErrors() {
    //   return this.handleFormValidation("email");
    // },

    phoneErrors() {
      return this.handleFormValidation("phone");
    },

    getLocationType() {
      return this.location_type;
    },
    getLocationId() {
      return this.location_id;
    },
    getLocationFunc() {
      return this.location_function;
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    isCollectionStatesLoaded: function() {
      return !this.collectionAddressServerData.states;
    },

    isCollectionCitiesLoaded: function() {
      return !this.collectionAddressServerData.cities;
    },

    isCollectionAreasLoaded: function() {
      return !this.collectionAddressServerData.areas;
    },

    collectionStateErrors: function() {
      return this.handleFormValidation("state_id");
    },
    collectionCityErrors: function() {
      return this.handleFormValidation("city_id");
    },
    collectionCountryErrors: function() {
      return this.handleFormValidation("country_id");
    },
    addressErrors() {
      return this.handleFormValidation("address");
    },
  },
};
</script>
